.App {
  text-align: center;
  /* background-image: url("assets/19333449.jpg"); */
  background-color: rgb(12, 15, 37);
  background-repeat: repeat;
  background-size:auto;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  background-image: linear-gradient(to right, #0f172a, #06171b);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}