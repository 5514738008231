.project-item {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: stretch;
    padding-left:20%;
    margin-bottom:10rem;
    text-align:justify;
}

.item {
    font-size: 1rem;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: stretch;
    cursor: default;
    user-select: none;
    width:auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-width: 100%;
    outline:2px solid rgba(255, 255, 255, 0);
    text-decoration: none;
    color: rgb(226, 226, 226);
    transition: 200ms;
}

.item:visited {
    font-size: 2rem;
    text-decoration: none;
    color: rgb(226, 226, 226);
    transition: 200ms;  
}

.item:hover {
    outline:2px solid rgba(255, 255, 255, 0.1);
    padding:1rem;
    border-radius:20px;
    background-color: rgba(85, 98, 193, 0.1);
    transition:200ms;
    transform:scale(1.1);
    cursor: pointer;
}

.item-name {
    font-size: 2rem;
    text-decoration: none;
    text-align: left;
}

.item-name:visited {
    color:rgb(226, 226, 226);
}

.item-desc {
    padding-top: 1.5rem;
}

.item-skill {
    /* padding-top: 1.5rem;
     */
    border: 2px solid rgb(15, 61, 93);
    border-radius: 20px;
    background-color: rgb(65, 112, 145);
    font-size: 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    width: 100%;
    margin-top: 2rem;
    cursor: default;
    user-select: none;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width:fit-content;
}