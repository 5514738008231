@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

html {
    scroll-behavior: smooth;
    scroll-padding-top: 70px;
}

.mainpage {
    color: white;
    font-family: var(--font-inter),ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-feature-settings: "ss03","cv02","cv11";
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 50%;
    margin-top: 4rem;
}

.left-side {
    width: 500px;
    min-width: 500px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	/* justify-content: center; */
	align-items: flex-start;
	align-content: stretch;
    height: 100%;
    position: sticky;
    top: 4rem;
    /* position:fixed; */
    /* top: 5rem; */
}

.right-side {
    width:600px;
    min-width: 600px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
    height: 100%;
    line-height:2rem;
    margin-right: 0.5rem;
    color: rgb(226, 226, 226);
}

.header-item {
    cursor: default;
    user-select: none;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
	align-content: stretch;
    opacity: 95%;
    text-align: justify
}

.right-side-item {
    padding-left: 20%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
	align-content: stretch;
    opacity: 95%;
}

.text-item {
    text-align: justify;
}

section {
    width:600px;
    min-width: 600px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
    height: 100%;
    line-height:2rem;
    color: rgb(226, 226, 226);
}

b {
    color: white;
}

ul {
    list-style-type: none;
    text-align:justify;
    font-size:2rem;
}

.job-item {
    margin-bottom:3rem;
    transition: 200ms ease;
    width: 100%;
    outline:2px solid rgba(255, 255, 255, 0);
    transform-origin: center;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom:1rem;
}

.job-item:hover {
    outline:2px solid rgba(255, 255, 255, 0.1);
    cursor: default;
    border-radius:20px;
    background-color: rgba(85, 98, 193, 0.1);
    padding:1rem;
    transition:200ms;
    transform:scale(1.1);
}

.job-skill {
    border: 2px solid rgb(15, 61, 93);
    border-radius: 20px;
    background-color: rgb(65, 112, 145);
    font-size: 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    width: 100%;
    margin-top: 0.5rem;
    cursor: default;
    user-select: none;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width:fit-content;
}

.job-skill-list {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
    padding-top:1rem;
    padding-bottom: 1.5rem;
}

.page-navigator:link {
    color: rgb(226, 226, 226);
    text-decoration: none;
}

.nav-items {
    margin-top:4rem;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: stretch;
    text-indent: -1em;
}

.nav-items li {
    padding-bottom:2rem;
    transition: 200ms;
}

.page-navigator:visited {
    color:rgb(226, 226, 226);
}

.resume-link:visited {
    color:rgb(226, 226, 226);
}

.nav-items li:hover {
    transition:200ms;
    transform:scale(1.2);
}

.soc-link {
    transition:200ms;
}

.soc-link:hover {
    transition: 200ms;
    transform:scale(1.2);
}